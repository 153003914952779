import {MetaFunction} from "@remix-run/node";
import {MainLayout} from "~/component/layout/MainLayout";
import {GeneralErrorBoundary} from "~/component/error-boundary";
import {json} from "@remix-run/server-runtime";
import {cn} from "~/component/ui/lib/utils";
import {Link} from "@remix-run/react";
import {Button} from "~/component/ui/button";
import {useMeta} from "~/hook/useMeta";
import {EmojiText, ExplanationWithScreenshot, H2, Li} from "~/component/content";
import {Hero, HeroTitle, HeroTitleEmphasis} from "~/component/Hero";
import {Card, CardContent} from "~/component/ui/card";
import {ArrowRightIcon} from "lucide-react";

export function loader() {
  return json({
  })
}

export default function Index() {
  return (
    <MainLayout
      menuItemTo={"/"}
      navbarMode={"primary"}
    >

      <Hero
        title={
          <HeroTitle variant={"light"}>
            Start a new
            <HeroTitleEmphasis className="">
              routine !
            </HeroTitleEmphasis>
          </HeroTitle>
        }
        subtitle={
          <>
            <Link to={"/install"}>
              <Button className="w-full lg:w-auto h-10 bg-[#FCB2E7] border-[#FCB2E7] text-neutral-800" variant={"outline"}>Commencer</Button>
            </Link>

            <Link to={"/contact-us"}>
              <Button className="w-full lg:w-auto h-10 text-black" variant={"outline"}>Nous contacter</Button>
            </Link>
          </>
        }
        img={"/img/hero.webp"}
        variant={"primary"}
      />

      <div className={"flex justify-center py-12 md:py-20 items-start px-4"}>
        <H2 className={"text-center "}>
          L’outil qui réunit clients, coachs et salles de sports en toute simplicité
        </H2>
      </div>

      <div
        className={"flex flex-col md:flex-row w-full space-y-8 md:space-y-0 my-8 md:mb-14 md:space-x-6 lg:space-x-12 px-6 lg:px-12 justify-center"}>
        <AppResume
          title={"Client"}
          titleColor={"text-secondary"}
          description={"Réservez un cours particulier rapidement et sans difficulté"}
          img={"/img/app-resume-customer-cover.webp"}
          to={"/app/customer"}
        />

        <AppResume
          title={"Salle"}
          titleColor={"text-primary"}
          description={"Offrez un outil incontournable à vos coachs et vos clients, tout en gardant la main"}
          img={"/img/app-resume-place-cover.webp"}
          to={"/app/place"}
        />

        <AppResume
          title={"Coach"}
          titleColor={"text-primary"}
          description={"Générez plus de revenus et prenez le contrôle sur votre activité de coaching"}
          img={"/img/app-resume-coach-cover.webp"}
          to={"/app/coach"}
        />
      </div>

      <ExplanationWithScreenshot
        title={
          <span>
            Réserver un cours <span className={"text-secondary italic"}>particulier</span> de sport n’a jamais été aussi simple
          </span>
        }
        img={"/img/home-book.webp"}
      >
        <p>
          Un accès facile à tous les coachs de nos salles partenaires, avec un système de réservation instantanée et
          de nombreuses options de paiement.
        </p>
      </ExplanationWithScreenshot>

      <div className={cn("pj-6 pb-12 px-4 md:px-8 space-y-8 flex flex-col md:flex-row items-center")}>
        <div className={"w-full md:w-1/3"}>
          <Link to={"/install"} className={"flex flex-col justify-center items-center"}>
            <img src={"/img/customer-icon.webp"} alt={"Installer l'app Jayne"} className={"h-[110px] md:h-[160px] rounded-3xl"}/>
          </Link>
        </div>
        <div className={"w-full md:w-2/3 sm:px-4 lg:px-8 space-y-6"}>
          <H2>Une expérience client à la hauteur de vos attentes</H2>

          <ul className={"space-y-2"}>
            <Li>Choix et diversité des cours</Li>
            <Li>Simplicité et rapidité d’utilisation</Li>
            <Li>Facilité de transaction</Li>
            <Li>Rappel et organisation</Li>
          </ul>

          <Link to={"/install"} className={"flex flex-col justify-center items-center md:items-start"}>
            <Button className={""} variant={"secondary"}>
              Téléchargez l'app →
            </Button>
          </Link>
        </div>
      </div>


      <div
        className={"flex flex-col py-6 md:py-12 bg-[#144f68] md:space-x-6 lg:space-x-12 justify-center md:space-y-6 lg:space-y-12"}>

        <div
          className={"flex flex-col items-center text-center md:text-left md:items-start lg:items-center px-4 md:px-12 space-y-3 md:space-y-0.5 lg:space-y-2"}>
          <H2 className={"text-white"}>
            Vous gérez une <span className={"text-secondary"}>salle</span> ?
          </H2>
          <p className={"text-neutral-200"}>Augmentez vos revenus et offrez un outil incontournable à vos coachs et
            vos clients</p>
        </div>

        <div
          className={cn("flex flex-col-reverse md:flex-row-reverse lg:space-x-12")}>

          <div className={"md:w-3/5 px-6 md:px-6 space-y-6 items-center"}>
            <div className={"text-white space-y-4"}>
              <EmojiText
                emoji={"🔥"}
                emojiBackgroundColor={"#fee0f5"}
                title={"Optimisation de la plannification"}
              />
              <EmojiText
                emoji={"🤩"}
                emojiBackgroundColor={"#def7e5"}
                title={"Identification des tendances"}
              />
              <EmojiText
                emoji={"💸"}
                emojiBackgroundColor={"#e0e9ff"}
                title={"Contrôle des flux financiers"}
              />
              <EmojiText
                emoji={"🚀"}
                emojiBackgroundColor={"#fee0f5"}
                title={"Sécurisation des paiements"}
              />
            </div>

            <div className={"flex justify-end lg:justify-start"}>
              <Button variant={"secondary"} asChild>
                <Link to={"/contact-us"}>
                  Avoir une démo →
                </Link>
              </Button>
            </div>
          </div>

          <div className={"p-8 md:p-8 md:w-2/5"}>
            <img src={"/img/home-place.webp"} alt={""} className={"w-auto max-h-[320px]"}/>
          </div>
        </div>
      </div>


      <div className={"bg-[#2E3DF4] pt-8 md:pt-0"}>
        <ExplanationWithScreenshot
          title={
            <p className={"text-white"}>
              En tant que <span className={"italic"}>coach</span>, générez plus de revenus et prenez le
              contrôle sur votre activité de coaching
            </p>
          }
          img={"/img/home-coach.webp"}
        >
          <span className={"text-white"}>Une gestion des disponibilités entièrement personnalisable, un suivi détaillé des performances commerciales, un
          encaissement automatique et une facturation simplifiée.</span>
        </ExplanationWithScreenshot>
      </div>

    </MainLayout>
  );
}

export const meta: MetaFunction<typeof loader> = ({data, error, matches}) => {
  return useMeta({
    path: "/",
    title: "Boostez votre coaching",
    description: "Un outil 360 pour la gestion et la réservation de cours particulier en salle de sport.",
    error,
    matches,
  });
};

export function ErrorBoundary() {
  return <GeneralErrorBoundary/>
}

const AppResume = (
  {
    title,
    titleColor,
    description,
    img,
    to,
  }: {
    title: string,
    titleColor: string,
    description: string,
    img: string,
    to: string,
  }) => (
  <div className={"md:w-1/3 lg:max-w-[400px]"}>

    <Link to={to}>
      <Card className="overflow-hidden rounded-lg shadow-lg">
        <img
          src={img}
          alt="Client"
          className="w-full h-48 object-cover"
          width="400"
          height="300"
          style={{ aspectRatio: "400/300", objectFit: "cover" }}
        />
        <CardContent className="p-4">
          <h3 className={cn(titleColor, "text-xl font-bold")}>{title}</h3>
          <p className="text-neutral-700">{description}</p>


          <Button variant={"link"} className={"text-black block mt-4"}>
            En savoir plus <ArrowRightIcon className="inline-block w-4 h-4" />
          </Button>
        </CardContent>
      </Card>

    </Link>
  </div>
);
